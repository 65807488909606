<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <v-simple-table>
    <template v-slot:default>
                    <tbody>
    <tr>
        
            <td class="text-start" v-text="item_num"></td>
            <td class="text-start" v-text="description_1"></td>
            <td>
                <v-select v-model="order_flag" :items="['W','D']" class="wd-style"></v-select>
            </td>
            <td>
                <v-text-field
                type="number"
                v-model.number="qty"
                class="qty-style"
                ></v-text-field
              >
            </td>
            <td>
                <v-text-field
                v-model="selling_price"
                prepend-inner-icon="mdi-currency-usd"
                class="currency-style"
              >
              </v-text-field>
            </td>
            <td>{{landed_cost | currency}}</td>
            <td>{{gpd | currency}}</td>
            <td>{{gpp | percent}}</td>
            <td>{{comm | currency}}</td>
            <td>
                <v-text-field
                type="number"
                v-model.number="on_hand"
                class="qty-style"
                ></v-text-field
              >
            </td>
            <td>
                <v-text-field
                type="number"
                v-model.number="commission"
                class="qty-style"
                ></v-text-field
              >
            </td>
            <td>
                <v-text-field
                type="number"
                v-model.number="on_order"
                class="qty-style"
                ></v-text-field
              >
            </td>
            <td></td>
    </tr>
                    </tbody></template>
                </v-simple-table>
            </v-expansion-panel-header>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    name:'InventoryRow',
    data:()=>({
        qty:1,
        selling_price:0,
        order_flag:'W'
    }),
    props:{
        description_1:{
            type:String,
            default:""
        },
        description_2:{
            type:String,
            default:""
        },
        item_num:{
            type:String,
            default:""
        },
        landed_cost:{
            type:Number
        },
        std_price:{
            type:Number,
            default:0
        },
        comms:{
            type:Array
        }
    },
    filters:{
        currency:function(val) {
            if(!val || !this.$_.isNumber(val)) return "-"
            return '$'+val.toFixed(2)
        },

        percent:function(val) {
            if(!val || !this.$_.isNumber(val)) return "-"
            if(val<1) val=val*100.0;

            return ''+val.toFixed(2)+' %'
        }
    },
    computed:{
        gpd() {
            if(!this.qty) return 0;

            return (this.selling_price-this.landed_cost)*this.qty
        },
        gpp() {
            if(!this.qty) return 0
            return this.gpd/(this.qty*this.selling_price)*100.0
        },
        comm() {

            if(!this.qty) return 0
            return 0;
        },
        commLevel() {
            return this.$_.find(this.comms,c=>this.selling_price>=c.s_price)
        }

    },
    created() {
        this.selling_price=this.std_price
    }
}
</script>

<style lang="scss" scoped>
.qty-style,
.wd-style {
  min-width: 65px;
  max-width: 80px;
}
.currency-style {
  min-width: 100px;
  max-width: 125px;
}
</style>