<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="4">
        
        <v-select
          :items="sortedCustomers"
          class="customer"
          item-text="customerName"
          display-field="customerName"
          :loading="customersLoading"
          v-model="SelectedCustomer"
          ref="custSelect"
          return-object
          outlined
          dense
          label="Select Customer"
          @change="customerChanged"
          append-outer-icon="mdi-plus"
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.customerName + "&nbsp;&nbsp;[" + data.item.customerNum + "]" }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.customerName + "&nbsp;&nbsp;[" + data.item.customerNum + "]" }}
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" xs="10" sm="9" md="8" lg="4" xl="4">
        <v-select
          :items="Addresses"
          class="customer"
          item-value="shipTo"
          item-text="name"
          display-field="name"
          v-model="shipTo"
          :disabled="!customerNum"
          outlined
          dense
          label="Ship To"
          append-outer-icon="mdi-plus"
          @click:append-outer="addressDialog = true"
        ></v-select>
      </v-col>
      <v-col cols="12" xs="10" sm="9" md="8" lg="2" xl="4">
        <v-select
          :items="filteredShipMethods"
          class="customer"
          :disabled="!customerNum"
          v-model="shipVia"
          outlined
          dense
          label="Ship Via"
        ></v-select>
      </v-col>
      </v-row>
        <v-dialog v-model="addressDialog" max-width="500px">
          <v-card>
            <v-card-title>Add New/Temporary Shipping Address</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Address 1" name="address1" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Address 2" name="address2" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field name="city" label="City"></v-text-field>
                    <!-- <v-text-field label="City" v-model="Inspection.propertyCity" autocomplete="new-password"/> -->
                  </v-col>
                  <v-col cols="3">
                    <v-text-field name="state" label="State"></v-text-field>
                    <!-- <v-text-field :label="propertyStatePlaceholder" v-model="Inspection.propertyState" autocomplete="new-password"/> -->
                  </v-col>
                  <v-col cols="3">
                    <v-text-field name="zipCode" label="Zip"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-col align="center" justify="space-around"
                ><v-btn color="primary" text small @click="SaveAddress"
                  >Save</v-btn
                >
              </v-col>
              <v-col align="center" justify="space-around"
                ><v-btn color="primary" text small @click="CancelAddress"
                  >Cancel</v-btn
                ></v-col
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
    
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
   <!-- <v-text-field
              :value="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
              @click:clear="ClearSearch"
              @click:append="LoadItems"
              @keydown.enter="LoadItems"
            >
            </v-text-field>          -->
            <vue-simple-suggest
    v-model="search"
    display-attribute="description_1"
    value-attribute="description_1"
    :list="getSuggestionList"
    mode="input"
@select="onSuggestSelect"
        :min-length="3"
        :debounce="200"
        @request-start="onRequestStart"
        @request-done="onRequestDone"
        @request-failed="onRequestFailed"
  >
  <v-text-field
              :value="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              :loading="itemListLoading"
              clearable
              @click:clear="ClearSearch"
              @click:append="LoadItems"
              @keydown.enter="LoadItems"
            >
            </v-text-field>

            <div slot="suggestion-item" slot-scope="{ suggestion, query }">
    <div>({{ suggestion.item_num }}) {{ suggestion.description_1 }}</div>
  </div>
  </vue-simple-suggest>
            <v-spacer></v-spacer>
            <v-checkbox
              v-model="commData"
              label="Show Commission Data"
              hide-details
            ></v-checkbox>
            <!-- <v-checkbox v-bind:label="header.text" v-model="header.selected" :value="header.selected" ></v-checkbox> -->
            <v-spacer></v-spacer>
            <v-checkbox
              v-model="prevSoldCheckbox"
              label="Previously Sold Items"
              hide-details
            ></v-checkbox>
          </v-card-title>
          <v-data-table
            :headers="getHeaders"
            :items="inventory"
            class="elevation-1"
            :server-items-length="inventoryCount"
            :options.sync="options"
            :page.sync="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items-per-page.sync="itemsPerPage"
            :search="search"
            :loading="itemTableLoading"
            show-expand
            item-key="itemKey">
            <template v-slot:item.orderFlag="{ item }">
              <v-select
                v-model="item.orderFlag"
                class="wd-style"
                item-text="text"
                item-value="text"
                :items="orderFlags"
                ></v-select
              >
            </template>
            <template v-slot:item.lastSoldPrice="{item}">
              {{ item.lastSoldPrice | currency }}
            </template>
            <template v-slot:item.lastSoldDate="{item}">
              {{ item.lastSoldDate | luxon }}
            </template>
            <template v-slot:item.qty="{ item }">
              <v-text-field
                type="number"
                v-model.number="item.qty"
                class="qty-style"
                ></v-text-field
              >
            </template>
            <template v-slot:item.sellingPrice="{ item }">
              <v-text-field
                v-model="item.sellingPrice"
                prepend-inner-icon="mdi-currency-usd"
                class="currency-style"
              >
              </v-text-field>
            </template>
            <template v-slot:item.landed_cost="{ item }">
              <v-text-field
                v-model="item.landed_cost"
                prepend-inner-icon="mdi-currency-usd"
                class="currency-style"
                readonly
                disabled
                >
                </v-text-field>
            </template>
            <template v-slot:item.gpd="{ item }">
              {{ (item.sellingPrice-item.landed_cost) | currency}}
            </template>
            <template v-slot:item.gpp="{ item }">
              {{ (item.sellingPrice-item.landed_cost)/item.sellingPrice | percent}}
            </template>
            <template v-slot:item.comm="{ item }">
              {{CalcEstComm(item) | currency}}
            </template>
            <template v-slot:item.Actions="{ item }">
              <v-btn rounded icon
              :disabled="!item.qty"
          color="primary"
          @click="AddToOrderClicked(item)"
          dark><v-icon>mdi-plus</v-icon></v-btn>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table>
                  <!-- <thead>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </thead> -->
                  <tbody>
                    <tr>
                      <td>Selling Price</td>
                      <td>{{ item.comms[0].s_price | currency }}</td>
                      <td>{{ item.comms[1].s_price | currency }}</td>
                      <td>{{ item.comms[2].s_price | currency }}</td>
                      <td>{{ item.comms[3].s_price | currency }}</td>
                      <td>{{ item.comms[4].s_price | currency }}</td>
                      <td>{{ item.comms[5].s_price | currency }}</td>
                    </tr>
                    <tr>
                      <td>Comm. %</td>
                      <td>{{ item.comms[0].pct | percent }}</td>
                      <td>{{ item.comms[1].pct | percent }}</td>
                      <td>{{ item.comms[2].pct | percent }}</td>
                      <td>{{ item.comms[3].pct | percent }}</td>
                      <td>{{ item.comms[4].pct | percent }}</td>
                      <td>{{ item.comms[5].pct | percent }}</td>
                    </tr>
                    <tr>
                      <td>On Hand: {{ item.oN_HAND }}</td>
                      <td>Committed: {{ item.committed }}</td>
                      <td>On Order: {{ item.oN_ORDER }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-expansion-panels v-model="panels">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Order Details
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
      <v-col>
        
          <v-data-table
            :headers="getHeaders"
            :items="items"
            class="elevation-1"
            
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            
            
            show-expand
            item-key="itemKey">
            
            <template v-slot:item.orderFlag="{ item }">
              <v-select
                v-model="item.orderFlag"
                class="wd-style"
                item-text="text"
                item-value="text"
                :items="orderFlags"
                ></v-select
              >
            </template>
            <template v-slot:item.qty="{ item }">
              <v-text-field
                type="number"
                :value.number="item.qty"
                @input="UpdateItemQty(item,$event)"
                class="qty-style"
                ></v-text-field
              >
            </template>

            <template v-slot:item.sellingPrice="{ item }">
              <v-text-field
                :value="item.sellingPrice"
                @input="updateItemPrice(item,$event)"
                prepend-inner-icon="mdi-currency-usd"
                class="currency-style"
              >
              </v-text-field>
            </template>
            <template v-slot:item.landed_cost="{ item }">
              <v-text-field
                v-model="item.landed_cost"
                prepend-inner-icon="mdi-currency-usd"
                class="currency-style"
                readonly
                disabled
                >
                </v-text-field
              >
            </template>
            <template v-slot:item.lastSoldDate="{item}">
              {{ item.lastSoldDate | luxon }}
            </template>
            <template v-slot:item.gpd="{ item }" >
              {{ (item.sellingPrice-item.landed_cost) | currency}}
            </template>
            <template v-slot:item.gpp="{ item }" >
              {{ (item.sellingPrice-item.landed_cost)/item.sellingPrice | percent}}
            </template>
            <template v-slot:item.comm="{ item }">
              {{CalcEstComm(item) | currency}}
            </template>
            <template v-slot:item.Actions="{ item }">
              <v-btn rounded
          color="primary"
          @click="RemoveFromOrder(item)" icon
          dark><v-icon>mdi-trash</v-icon></v-btn>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td>Selling Price</td>
                      <td>{{ item.comms[0].s_price | currency }}</td>
                      <td>{{ item.comms[1].s_price | currency }}</td>
                      <td>{{ item.comms[2].s_price | currency }}</td>
                      <td>{{ item.comms[3].s_price | currency }}</td>
                      <td>{{ item.comms[4].s_price | currency }}</td>
                      <td>{{ item.comms[5].s_price | currency }}</td>
                    </tr>
                    <tr>
                      <td>Comm. %</td>
                      <td>{{ item.comms[0].pct | percent }}</td>
                      <td>{{ item.comms[1].pct | percent }}</td>
                      <td>{{ item.comms[2].pct | percent }}</td>
                      <td>{{ item.comms[3].pct | percent }}</td>
                      <td>{{ item.comms[4].pct | percent }}</td>
                      <td>{{ item.comms[5].pct | percent }}</td>
                    </tr>
                    <tr>
                      <td>On Hand: {{ item.oN_HAND }}</td>
                      <td>Committed: {{ item.committed }}</td>
                      <td>On Order: {{ item.oN_ORDER }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </td>
            </template>
            <template v-slot:footer.prepend v-if="items.length>0">
              <v-btn
          rounded
          color="warning"
          dark
          @click="ClearCustomerOrder"
        >
          Clear Order
        </v-btn>
            </template>
          </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
      <v-text-field
            outlined
            dense
            label="Qty"
            type="number"
            :value="TotalQty"
            class="qty-style"
            readonly
            ></v-text-field>
      </v-col>
      <v-col>
      <v-text-field
            outlined
            dense
            label="Total"
            :value="TotalSell"
            prepend-inner-icon="mdi-currency-usd"
            class="currency-style"
            readonly
          >
          </v-text-field>
          </v-col>
      <v-col v-if="commData">
        <v-text-field
            outlined
            dense
            label="Cost"
            :value="TotalCost"
            prepend-inner-icon="mdi-currency-usd"
            class="currency-style"
            readonly
            ></v-text-field>
            </v-col>
      <v-col v-if="commData">
      <v-text-field
            outlined
            dense
            label="GP$"
            :value="TotalGPD"
            prepend-inner-icon="mdi-currency-usd"
            class="currency-style"
            readonly
            ></v-text-field>
            </v-col>
      <v-col v-if="commData">
        <v-text-field
            outlined
            dense
            label="GP%"
            append-icon="mdi-percent-outline"
            class="currency-style"
            readonly
            :value="TotalGPP"
            ></v-text-field>
            </v-col>
      <v-col v-if="commData">
        <v-text-field
            outlined
            dense
            label="Comm"
            :value="TotalComm"
            prepend-inner-icon="mdi-currency-usd"
            class="currency-style"
            readonly
            ></v-text-field>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-btn @click="TestNotifications">Test</v-btn>
    </v-row> -->
    <v-row>
      
      <v-col cols="8">
        <v-select v-model="pmtMethod" :items="PaymentMethods" item-value="id">
          <template v-slot:item="{item,on}">
            <v-list-item v-on="on">
          <v-list-item-content>
            <v-list-item-title>
              {{item.description}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
        {{item.description}}
        
      </template>
        </v-select>
      </v-col>
      <v-col class="d-flex align-end flex-column" cols="4">
        <v-btn
          rounded
          :disabled="items.length==0"
          color="primary"
          @click="SubmitOrder"
        >
          Submit
        </v-btn>
      </v-col>
      </v-row>
      </v-expansion-panel-content>
      </v-expansion-panel>
      </v-expansion-panels>
      <!--  -->
    </v-row>
  </v-container>
</template>

<script>
import { CommissionService } from "@/service/commission_service";
import {mapActions, mapGetters,mapMutations} from "vuex";
import InventoryRow from "@/components/InventoryRow"
import { mapFields } from 'vuex-map-fields';
import _ from 'underscore';
import { msalMixin } from 'vue-msal';
import VueSimpleSuggest from 'vue-simple-suggest'
const { DateTime } = require("luxon");
  // import 'vue-simple-suggest/dist/styles.css'
//import InventoryItem from "@/plugins/inventory_item.js"
//const InventoryItem = require('@/Inventory');

// class InventoryItem{
//     constructor(obj) {
//         this.item_num=obj.item_num;
//         this.description_1=obj.description_1;
//         this.description_2=obj.description_2;
//         this.landed_cost=obj.landed_cost;
//         this.std_price=obj.std_price;
//         this.comms=obj.comms;
//         this.qty=0;
//         this.sellingPrice=obj.std_price;
//     }

//     getGPD() {
//       return 10;
//     }
//   }

function DteSort(a,b) {
  debugger
}


export default {
  name: "Commissions",
mixins: [msalMixin],
  data: () => ({
    headers: [
      {
        text: "Item Number",
        align: "start",
        sortable: false,
        value: "item_num",
        selected: true
      },
      { text: "Description 1", value: "description_1", selected: true },
      { text: "W/D", value: "orderFlag", selected: true },
      { text: "Qty", value: "qty", selected: true },
      { text: "Sell", value: "sellingPrice", selected: true },
      { text: "Cost", value: "landed_cost", selected: false },
      { text: "GP $", value: "gpd", selected: false },
      { text: "GP %", value: "gpp", selected: false },
      { text: "Comm", value: "comm", selected: false },
      { text: "",value:"Actions", selected: true },
    ],
    options:{},
    panels:0,
    
    customers: [],
    inventory: [],
    ShipMethods:[],
    inventoryCount:0,
    cost: 0,
    snack: false,
    snackColor: "",
    snackText: "",
    itemListLoading:false,
    searchTmp:"",
    search: "",
    estComm: 0,
    totalComm: 0,
    order_qty_total: 0,
    order_sell_total: 0,
    order_cost_total: 0,
    order_gpd_total: 0,
    order_gpp_total: 0,
    singleExpand: false,
    addressDialog: false,
    expanded: [],
    parentId: "",
    customersLoading:false,
    itemTableLoading:false,
    
    commService:null,
    orderFlags: [
      { text: "W", value: 0 },
      { text: "D", value: 1 },
    ],
    SelectedCustomer:{addresses:[]},
    
  }),
  props: {
    inventoryLoading:{
      type:Boolean,
      default:false
    }
  },
  components:{InventoryRow,VueSimpleSuggest},
  watch: {
    customers(v) {
      if(this.customerNum) {
        var tmp=this.$_.find(v,c=>c.customerNum==this.customerNum)
        if(tmp) {
          this.SelectedCustomer=tmp;
          this.LoadItems();
        }
      }
    },
    inventoryLoading(v) {
     if(!v && this.customerNum && !this.AddBtnShown) this.LoadItems();  
    },
    prevSoldCheckbox(v) {
     if(this.customerNum) this.LoadItems(); 
    },
    sortBy(v) {if(this.customerNum) this.LoadItems();},
    sortDesc(v) {if(this.customerNum) this.LoadItems();},
    itemsPerPage(v) {if(this.customerNum) this.LoadItems();},
    currentPage(v) {if(this.customerNum) this.LoadItems();},
    
    // dbName(v) {
    //   initJsStore(v)
    //     .then(res=>
    //       this.isDbCreated=res
    //     )
    //     .catch(err=>{
    //       console.error(err)
    //           this.$raygun('send',{error:err})
    //     })
    // },
    isAuthenticated(v) {
      if(v)
    {
      this.$http.defaults.headers.common["Authorization"] ="Bearer " + v;
      this.LoadCustomers();
      this.LoadShipMethods();
    }
      
    },
    // isDbCreated(v) {
    //   if(v) {
    //     this.commService=new CommissionService();
    //     this.LoadCustomers();
    //     this.StartItemSync();
    //   } 
    // }
  },
  methods: {
    ...mapMutations("Order",["addItemToOrder", "clearItems","removeItemFromOrder","UpdateItem"]),
    getSuggestionList(inputVal) {

      return this.commService.GetInventoryList(inputVal,this.prevSoldCheckbox,this.customerNum)
      
    },
    onSuggestSelect(suggest) {
      this.search=suggest.description_1
      this.LoadItems()
    },
    onRequestStart (value) {
        this.itemListLoading = true
      },
      onRequestDone (e) {
        this.itemListLoading = false
      },
      onRequestFailed (e) {
        this.itemListLoading = false
      },
    UpdateItemQty(itm,val){
        this.UpdateItem({item_num:itm.item_num,um:itm.um_selling,qty:parseInt(val)})
      },
      updateItemPrice(itm,val) {
      this.UpdateItem({item_num:itm.item_num,um:itm.um_selling,sellingPrice:parseFloat(parseFloat(val).toFixed(2))})
    },
      SubmitOrder() {
        var this1=this
        this.$http.post('https://api.hockenbergs.com/api/Order',this.Order,this.axiosHeaders)
        .then(res=>{
          this1.clearItems()
          this1.$snotify.success(`Order ID: ${res.data.orderId}`,"Order Submitted")
        })
        .catch(err=>{
          
          let msg=this1.$_.keys(err.response.data).reduce((t,k)=>{

            let e=`<ul>${k}`+err.response.data[k].map(v=>`<li>${v}</li>`).join('')+'</ul>'

            return t+e
          },"")
          this1.$snotify.html('<div class="snotifyToast__title"><b>Error</b></div><div class="snotifyToast__body">'+msg+'</div>',{
            timeout: 10000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            type:"error"
          })
          this1.$sendError(err,{order:this1.Order,err_details:err.response.data})
        })
      },
      LoadShipMethods() {
        var this1=this
        this.$http.get('https://api.hockenbergs.com/api/customers/ShipVia',this.axiosHeaders)
        .then(res=>{
          this1.ShipMethods = res.data;
          this1.commService.addShipMethods(res.data);
        })
        .catch(err=>{
          if(err.response.status==401 && err.config.headers.Authorization) {
                  msal.acquireTokenSilent({scopes:["user.read"],account:msal.account,forceRefresh:true})
      .then(r=>this.authenticated(r))
      .catch(e=>this.$sendError(e));
                } else {
          this1.$sendError(err)
          this1.commService.getShipMethods()
          .then(results=>this1.ShipMethods=results)
          .catch(e2=>this1.$raygun('send',{error:e2}))
                }
        })
      },
      ClearSearch() {
        this.search="";
        this.LoadItems()
      },
    LoadCustomers() {
      
      this.customersLoading=true;
      
      var this1 = this;

      this.$http
        .get("https://api.hockenbergs.com/api/Customers",this.axiosHeaders)
        .then((resp) => {
          this1.customers = resp.data;
          this1.commService.addCustomers(resp.data);
          resp.data.map((c) => {
            var that=this;
            this.$http
              .get(`https://api.hockenbergs.com/api/customers/${c.customerNum}/Orders`,this.axiosHeaders)
              .then((r) => that.commService.addCustomerOrders(r.data))
              .catch((e) => {
                if(err.response.status==401 && err.config.headers.Authorization) {
                  msal.acquireTokenSilent({scopes:["user.read"],account:msal.account,forceRefresh:true})
      .then(r=>this.authenticated(r))
      .catch(e=>this.$sendError(e));
                } else {
                  that.$sendError(err,{Customer:c})
                  }
              });
          }, this1);
        })
        .catch((err) => {
                if(err.response.status==401 && err.config.headers.Authorization) {
                  msal.acquireTokenSilent({scopes:["user.read"],account:msal.account,forceRefresh:true})
      .then(r=>this.authenticated(r))
      .catch(e=>this.$sendError(e));
                } else {
                  this1.$sendError(err)

                  this1.commService.getCustomers()
                .then(resp=>{
                  this1.customers = resp
        })
                .catch(e2=>{
                  this1.$raygun('send',{error:e2})
                })
                }
                
                
                
              })
        .finally(()=>this.customersLoading=false); 
    },
    LoadCustomerOrders() {
      this.customers.map((c) => {
        this.$http
          .get(`https://api.hockenbergs.com/api/customers/${c.customerNum}/Orders`,this.axiosHeaders)
          .then((r) => this.commService.addCustomerOrders(r.data))
          .catch((e) => {
            this.$sendError(err,{CustomerNumber:c.customerNum})
            console.error(e)
          });
      });
    },
    ClearCustomerOrder() {
      var self=this;
      this.$snotify.confirm('Are you sure you want to delete ALL order details?','Clear Order?',{
        showProgressBar: true,
        closeOnClick:true,
        pauseOnHover:true,
        buttons:[
          {text:'Yes',action:(toast)=>{self.clearItems();self.$snotify.remove(toast.id);}},
          {text:'No',action: (toast) => this.$snotify.remove(toast.id)}
        ]
      })
      // if(confirm('Are you sure you want to delete ALL order details?' + this.items.length)) {
      //   //This only removes rows from grid
      //   // this.Order.lines.splice(0, this.Order.lines.length)
      //   //Remove rows from localstorage
      //   this.clearOrder()
      // }
    },
    
    LoadItems(pg) {
      this.itemTableLoading=true;
      this.inventory=[];
      var that=this;
      
      let srtby=this.sortBy||"description_1";

      if(srtby=="lastSoldDate") srtby=(a,b)=>{
        
        var parseDate = function parseDate(dte) {
          if(_.isString(dte))
          {
            let dateParts = dte.split("/");
          return new Date(parseInt(dateParts[2], 10), parseInt(dateParts[1], 10) - 1, parseInt(dateParts[0], 10)).getTime();
          
          } else if(_.isNumber(dte)) {
            return new Date(dte).getTime()
          } else if(_.isObject(dte)){
            let dtestr=dte.lastSoldDate||"1970-01-01"
          return new Date(dtestr).getTime()
          }
          
          return 0;

          };

          return parseDate(b) - parseDate(a);

        return a-b;
      };
      

      if(this.prevSoldCheckbox) {
        this.commService.GetItemsByPage(this.customerNum,this.currentPage-1,this.itemsPerPage,srtby,this.sortDesc,this.search)
        .then(recs=>{
          var pLevel=(that.SelectedCustomer.priceLevel || 1) -1
          
          if(recs) {
            that.inventoryCount=recs.totalCount
          that.inventory=(recs.items || []).map(i=>{
            i.sellingPrice=(Math.round((i.std_price*i.comms[pLevel].mult)*100)/100.00).toFixed(2);
            
            //i.landedCost=i.landed_cost;
            i.orderFlag='W';
            i.qty=0;
return i;
          })
          } else {
            that.inventoryCount=0
            that.inventory=[]
          }
        }
          
          )
        .catch(err=>console.error(err))
        .finally(()=>that.itemTableLoading=false)
      } else {
        this.commService.GetAllItemsByPage(this.customerNum,this.currentPage-1,this.itemsPerPage,this.search,srtby,this.sortDesc)
        .then(recs=>{
          var pLevel=(that.SelectedCustomer.priceLevel || 1) -1
          if(recs) {
            that.inventoryCount=recs.totalCount
          that.inventory=recs.items.map(i=>{
            i.sellingPrice=(Math.round((i.std_price*i.comms[pLevel].mult)*100)/100.00).toFixed(2);
            i.landed_cost=i.landed_cost;
            i.orderFlag='W';
            i.qty=0;
return i;
          })
          } else {
            that.inventory=[]
            that.inventoryCount=0
          }
        }
          )
        .catch(err=>console.error(err))
        .finally(()=>that.itemTableLoading=false)
      }
      
    },
    customerChanged(v) {
      // TODO: Code for when customer is changed
      // * Filter items in 'Ship To' dropdown to show the corresponding ship to addresses (as array) from the Customer selected in 'Select Customer' dropdown.
      // this.$emit('customer-updated', this.value)
      // this.$snotify.info("Customer Changed")
      this.customerNum=v.customerNum;
      //this.newOrder.customerNum=v.customerNum;
      this.shipTo=v.shipTo;
      this.shipVia=v.shipVia;

      this.LoadItems()
    },
    
    AddToOrderClicked(item) {
      this.addItemToOrder(this.$_.clone(item));
      item.qty=0;
    },
    RemoveFromOrder(item) {
      this.removeItemFromOrder(item)
    },
    close() {
      console.log("Dialog closed");
    },
    AddAddressDialog() {
      // TODO: method to add address goes here
    },
    SaveAddress() {
      // if no errors, close the dialog:
      this.addressDialog = false;

      // this.$snotify.success("Shipping Address Saved");
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Shipping Address Saved";
    },
    CancelAddress() {
      this.addressDialog = false;
    },
    CalcEstComm(itm) {
      if(!itm.qty) return null;
      
      var comm=this.$_.find(itm.comms,c=>itm.sellingPrice>=c.s_price)
      
      return comm.pct*(itm.sellingPrice-itm.landed_cost)*itm.qty
    },
  },
  filters:{
        currency:function(val) {
            if(!val || !_.isNumber(val)) return "-"
            return '$'+val.toFixed(2)
        },

        percent:function(val) {
            if(!val || !_.isNumber(val)) return "-"
            if(val<1) val=val*100.0;

            return ''+val.toFixed(2)+' %'
        },
        
    },
  computed: {
    ...mapGetters("Order",["TotalComm","TotalQty","TotalSell","TotalCost","TotalGPD","TotalGPP","Order"]),
    ...mapGetters("User",["axiosHeaders","isAuthenticated"]),
    ...mapFields("User",["commData","sortBy","sortDesc","itemsPerPage","currentPage","prevSoldCheckbox"]),
    ...mapFields("Order",["customerNum","shipTo","shipVia","items","pmtMethod"]),
//     options:{
//       get() {
// /*
// {
//     "page": 1,
//     "itemsPerPage": 10,
//     "sortBy": "",
//     "sortDesc": [
//         false
//     ],
//     "groupBy": [],
//     "groupDesc": [],
//     "mustSort": false,
//     "multiSort": false
// }
// */

//         return this.$_.extend(this.options_Base,{
//           sortBy:this.sortBy,
//           sortDesc:this.sortDesc,
//           page:this.currentPage,
//           itemsPerPage:this.itemsPerPage
//         })
//       },
//       set(v) {
//         this.sortBy=v.sortBy;
//         this.currentPage=v.page;
//         this.itemsPerPage=v.itemsPerPage;
//         this.sortDesc=v.sortDesc;
//         this.options_Base=this.$_.omit(v,['sortBy','sortDesc','itemsPerPage','page'])
//       }
//     },
    filteredShipMethods() {
      var tmp=['Best Way']

      if(this.shipVia) tmp.push(this.shipVia);

      if(this.SelectedCustomer) {
        return this.$_.union(tmp,this.$_.pluck(this.$_.filter(this.ShipMethods,m=>m.warehouse==this.SelectedCustomer.defaultBranch),'shipMethod'))
      } else {
        return this.$_.union(tmp,this.$_.pluck(this.ShipMethods,'shipMethod'))
      }


    },
    AddBtnShown() {
      return this.inventory.reduce((t,i)=>t+i.qty,0)>0
    },
    PaymentMethods() {
      let methods=this.SelectedCustomer.paymentMethods || []
      
      methods.push({id:'House',description:'Hockenbergs Acct'})

      return methods
    },
    // dbName() {
    //   return this.user.name
    // },
    getHeaders() {
      if(this.commData) {
        return [
      {
        text: "Item Number",
        align: "start",
        value: "item_num"
      },
      { text: "Description 1", value: "description_1" },
      { text: "UOM", value: "um_selling"},
      { text: "W/D", value: "orderFlag" },
      { text: "Qty", value: "qty" },
      { text: "Sell", value: "sellingPrice" },
      { text: "Cost", value: "landed_cost" },
      { text: "Last $", value: "lastSoldPrice" },
      { text: "Last Date", value: "lastSoldDate",sort: DteSort},
      { text: "GP $", value: "gpd" },
      { text: "GP %", value: "gpp" },
      { text: "Comm", value: "comm" },
      { text: "",value:"Actions" },
    ]
      } else {
        return [
      {
        text: "Item Number",
        align: "start",
        value: "item_num"
      },
      { text: "Description 1", value: "description_1" },
      { text: "UOM", value: "um_selling"},
      { text: "W/D", value: "orderFlag" },
      { text: "Qty", value: "qty" },
      { text: "Sell", value: "sellingPrice" },
      { text: "Last $", value: "lastSoldPrice" },
      { text: "Last Date", value: "lastSoldDate" },
      { text: "",value:"Actions" },
    ]
      }
    },
    sortedCustomers() {
      return this.$_.sortBy(this.customers,"customerName");
    },
    Addresses() {
      return this.SelectedCustomer.addresses
    },
  },
  // async created() {
  //   try {
  //     const isDbCreated = await initJsStore();
  //     if (isDbCreated) {
  //       console.log("db created");
  //       // prefill database
  //     } else {
  //       console.log("db opened");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },
  created() {
    this.commService=new CommissionService();
  },
  mounted() {
    // if(this.IDToken){
    //   var that=this;
    //   this.$http.defaults.headers.common["Authorization"] ="Bearer " + this.IDToken;
    //   initJsStore(this.dbName)
    //     .then(res=>
    //       that.isDbCreated=res
    //     )
    //     .catch(err=>{
    //       console.error(err)
    //           this.$raygun('send',{error:err})
    //     })
    // }
    if(this.isAuthenticated)
    {
      this.LoadCustomers();
      this.LoadShipMethods();
    }
    
    // this.StartItemSync();
    // var this1 = this;
    // this.LoadItems();
    // authentication
    //   .acquireToken()
    //   .then((token) => {
    //     // Set default request headers for every request
    //     this1.$http.defaults.headers.common["Authorization"] =
    //       "Bearer " + token;
    //     this1.LoadCustomers();
    //   })
    //   .catch((e) => console.log("Error", e));

    // authentication
    //   .acquireToken()
    //   .then((token) => {
    //     this1.$http.defaults.headers.common["Authorization"] =
    //       "Bearer " + token;
    //     this1.LoadCustomers();
    //     //this1.LoadItems();
    //   })
    //   .catch((e) => console.error(e));
  }
};
</script>
<style lang="scss" scoped>
.qty-style,
.wd-style {
  min-width: 65px;
  max-width: 80px;
}
.currency-style {
  min-width: 100px;
  max-width: 125px;
}
</style>
